import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const scoreAverage = (value) => {
  if (value || value === 0) {
    return Number(value).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2
    });
  } else {
    return 'unknown';
  }
};
const renderIntent = (intent, index, intentsLabel, scoresLabel) => {
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="flex-end"
      key={Math.random()}
    >
      {index === 0 && (
        <React.Fragment>
          <Grid item xs={9}>
            {intentsLabel}:
          </Grid>
          <Grid item xs={2}>
            {' '}
            {index === 0 && <span>{scoresLabel}:</span>}
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={9}>
        #{intent.intent.replaceAll('_', ' ')}
      </Grid>
      <Grid item xs={2}>
        {scoreAverage(intent.score)}
      </Grid>
    </Grid>
  );
};

const ConversationItemIntents = (props) => {
  const { t } = useTranslation();
  const intentsLabel = t('Intents');
  const scoresLabel = t('Scores');

  return (
    <React.Fragment>
      {props.intents ? (
        props.intents.slice(0, 3).map((intent, index) => {
          return renderIntent(intent, index, intentsLabel, scoresLabel);
        })
      ) : (
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item xs={8}>
            {t('No intents')}
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ConversationItemIntents;
