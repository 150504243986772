import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEN from './en/en.json';
import translationsCZ from './cz/cz.json';
import i18next from 'i18next';

const resources = {
  en: {
    translation: translationsEN
  },
  cz: {
    translation: translationsCZ
  }
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    fallbackLng: 'cz', // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
