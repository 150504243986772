import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

export default createMuiTheme({
  palette: {
    primary: {
      main: green[700]
    },
    trainingGray: {
      backgroundColor: grey[600],
      color: '#fff'
    },
    trainingGreen: {
      backgroundColor: green[700],
      color: '#fff'
    },
    trainingRed: {
      backgroundColor: red[700],
      color: '#fff'
    },
    trainingBlue: {
      backgroundColor: blue[700],
      color: '#fff'
    },
    trainingYellow: {
      backgroundColor: yellow[800],
      color: '#fff'
    }
  }
});
