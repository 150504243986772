import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const scoreAverage = (value) => {
  if (value || value === 0) {
    return Number(value).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2
    });
  } else {
    return 'unknown';
  }
};

const renderEntity = (entity, index, entitiesLabel, scoresLabel) => {
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="flex-end"
      key={Math.random()}
    >
      {index === 0 && (
        <React.Fragment>
          <Grid item xs={9}>
            {entitiesLabel}:
          </Grid>
          <Grid item xs={2}>
            {' '}
            {index === 0 && <span>{scoresLabel}:</span>}
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={9}>
        @{entity.entity}: {entity.value}{' '}
      </Grid>
      <Grid item xs={2}>
        {scoreAverage(entity.score)}
      </Grid>
    </Grid>
  );
};

const ConversationItemEntities = (props) => {
  const { t } = useTranslation();
  const entitiesLabel = t('Entities');
  const scoresLabel = t('Scores');

  return (
    <React.Fragment>
      {props.entities ? (
        props.entities.map((entity, index) => {
          return renderEntity(entity, index, entitiesLabel, scoresLabel);
        })
      ) : (
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item xs={8}>
            {t('No entities')}
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ConversationItemEntities;
