import keycloak from '../api/Keycloak';

const TRAINERS = ['ADMIN', 'TRAINING_TRAINER', 'TRAINING_CUSTOMER_TRAINER'];
const INTERNAL_TRAINERS = ['ADMIN', 'TRAINING_TRAINER'];

export const isTrainer = (project) => {
  const authorities = keycloak.realmAccess.roles;
  if (authorities.includes(TRAINERS[0])) return true;
  return hasRequiredAuthority(project, authorities, TRAINERS);
};

export const isInternalTrainer = (project) => {
  const authorities = keycloak.realmAccess.roles;
  if (isAdmin()) return true;
  return hasRequiredAuthority(project, authorities, INTERNAL_TRAINERS);
};

const isAdmin = () => {
  const authorities = keycloak.realmAccess.roles;
  return authorities.includes(TRAINERS[0]);
};

const hasRequiredAuthority = (project, authorities, requiredAuthorities) => {
  return authorities
    .filter((authority) =>
      requiredAuthorities.some((it) => authority.startsWith(it))
    )
    .some((it) => {
      const splitRole = it.split(':');
      return (
        TRAINERS.some((it) => it === splitRole[0]) && splitRole[1] === project
      );
    });
};
