import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { Add, Remove } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const ConversationItemResponseDetails = (props) => {
  const classes = props.styles;
  const [openOptions, setOpenOptions] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.message.type && props.message.type === 'TEXT' ? (
        <Grid item container>
          <Grid item xs={3}>
            <span style={{ fontSize: '12px', color: '#999999' }}>
              {t('Input type')}: {props.inputType}
            </span>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paperRight}>{props.message.text}</Paper>
          </Grid>
        </Grid>
      ) : null}
      {props.message.type && props.message.type === 'OPTIONS' ? (
        openOptions ? (
          <Grid item container justify="flex-end">
            <Button
              size="small"
              startIcon={<Remove />}
              onClick={() => setOpenOptions(false)}
            >
              Options{' '}
              {props.message.options
                ? '(' + props.message.options.length + ')'
                : null}
            </Button>
          </Grid>
        ) : (
          <Grid item container justify="flex-end">
            <Button
              size="small"
              startIcon={<Add />}
              onClick={() => setOpenOptions(true)}
            >
              Options{' '}
              {props.message.options
                ? '(' + props.message.options.length + ')'
                : null}
            </Button>
          </Grid>
        )
      ) : null}
      {props.message.type &&
      props.message.options &&
      props.message.type === 'OPTIONS' ? (
        <Grid item container justify="flex-end">
          <Collapse in={openOptions}>
            {props.message.options.map((option, index) => {
              return (
                <Paper key={index} className={classes.paperRightOptions}>
                  {option.text}
                </Paper>
              );
            })}
          </Collapse>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default ConversationItemResponseDetails;
