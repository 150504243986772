import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 250,
    maxWidth: 250
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350
    }
  }
};

const GreenCheckbox = withStyles({
  root: {
    color: green[600]
  }
})((props) => <Checkbox color="default" {...props} />);

const ConversationStatusSelect = (props) => {
  const classes = useStyles();
  const [selectedStatuses, setSelectedStatuses] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedStatuses(props.selectedStatuses);
  }, [props.selectedStatuses]);

  const handleChange = (event) => {
    setSelectedStatuses(event.target.value);
    props.setSelectedStatuses(event.target.value);
    console.log('Selected Statuses: ' + event.target.value);
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">
          {t('Conversation Status')}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedStatuses}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {props.statuses
            ? props.statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <GreenCheckbox
                    checked={selectedStatuses.indexOf(status) > -1}
                  />
                  <ListItemText primary={status} />
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default ConversationStatusSelect;
