import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MaterialSpinner from '../MaterialSpinner/MaterialSpinner';
import CoachingKangaroo from '../../../api/CoachingKangaroo';
import MaterialTableRow from '../MaterialTableRow/MaterialTableRow';
import * as conversationService from '../../../services/conversationService';
import {
  formatDateToYyyyMmDd,
  TIME_FROM,
  TIME_TO
} from '../../../utils/formattingUtils';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'text', label: 'Conversation' },
  { id: 'answer-count', label: 'Answers' },
  { id: 'first-score-average', label: 'Score (avg)' },
  { id: 'started-at', label: 'Date' },
  { id: 'feeling', label: 'Feeling' },
  { id: 'status', label: 'Status' }
];

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

const addFilter = (filter) => {
  let filterUrl = '';
  if (filter) {
    if (filter.dateFrom) {
      filterUrl +=
        '&at_from=' + formatDateToYyyyMmDd(filter.dateFrom) + TIME_FROM;
    }
    if (filter.dateTo) {
      filterUrl += '&at_to=' + formatDateToYyyyMmDd(filter.dateTo) + TIME_TO;
    }
    if (filter.selectedIntents && filter.selectedIntents.length > 0) {
      filterUrl += '&intents=' + filter.selectedIntents;
    }
    if (filter.selectedEntities && filter.selectedEntities.length > 0) {
      filterUrl += '&entities=' + filter.selectedEntities;
    }
    if (filter.selectedEntityValues && filter.selectedEntityValues.length > 0) {
      filterUrl += '&entityValues=' + filter.selectedEntityValues;
    }
    if (filter.selectedStatuses && filter.selectedStatuses.length > 0) {
      filterUrl += '&statuses=' + filter.selectedStatuses;
    }
    if (filter.selectedFeelings && filter.selectedFeelings.length > 0) {
      filterUrl += '&feelings=' + filter.selectedFeelings;
    }
    if (filter.selectedRatings && filter.selectedRatings.length > 0) {
      filterUrl += '&ratings=' + filter.selectedRatings;
    }
    if (filter.selectedPlatforms && filter.selectedPlatforms.length > 0) {
      filterUrl += '&platforms=' + filter.selectedPlatforms;
    }
    if (filter.selectedContext && filter.selectedContext.length > 0) {
      filterUrl += '&context=' + filter.selectedContext;
    }
    if (
      filter.selectedContextValues &&
      filter.selectedContextValues.length > 0
    ) {
      filterUrl += '&contextValues=' + filter.selectedContextValues;
    }
    if (filter.scoreFrom) {
      filterUrl += '&score_from=' + filter.scoreFrom;
    }
    if (filter.scoreTo) {
      filterUrl += '&score_to=' + filter.scoreTo;
    }
    if (filter.fulltext) {
      filterUrl += '&fulltext=' + filter.fulltext;
    }
    if (filter.aiFilter !== 'NO AI Filter') {
      filterUrl += '&ai_filter=';
      if (filter.aiFilter === 'AI Rated') {
        filterUrl += 'AI_RATED';
      } else {
        filterUrl += 'AI_NOT_RATED';
      }
    }
  }
  return filterUrl;
};

const StickyHeadTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const { project } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setPage(0);
  }, [project, props.filter]);

  useEffect(() => {
    if (props.dirtyConversation) {
      console.log('Dirty conv:', props.dirtyConversation);
      const index = data.findIndex(
        (it) => it.id === props.dirtyConversation.id
      );
      data[index] = props.dirtyConversation;
      setData([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dirtyConversation]);

  useEffect(() => {
    if (props.filter) {
      if (props.filter.inputSessionID) {
        getConversation(props.filter.inputSessionID);
      } else {
        setDataFetched(false);
        CoachingKangaroo.get(
          '/api/training/v1/' +
            encodeURIComponent(project) +
            '/conversations' +
            '?limit=' +
            rowsPerPage +
            '&offset=' +
            page * rowsPerPage +
            (page === 0 ? '&total=true' : '') +
            addFilter(props.filter)
        )
          .then((response) => {
            if (page === 0) {
              if (response.data && response.data['total-count'] !== undefined) {
                setTotalCount(response.data['total-count']);
              } else {
                setTotalCount(-1);
              }
            }
            if (response.data && response.data['conversations']) {
              setData(response.data.conversations);
            } else {
              setData([]);
            }
            setDataFetched(true);
          })
          .catch((error) => {
            setTotalCount(-1);
            setData([]);
            setDataFetched(true);
            console.error('Error while fetching data', error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, props.filter]);

  const getConversation = (sessionID) => {
    conversationService
      .getConversation(project, sessionID)
      .then((response) => {
        const result = response.data;
        if (result) {
          setData([result]);
          setTotalCount(1);
        } else {
          setData([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.error('Error while fetching data', error);
      });
  };
  const handleChangePage = (event, newPage) => {
    setDataFetched(false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setDataFetched(false);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const labelDisplayedRows = (obj) => {
    return (
      obj.from +
      '-' +
      obj.to +
      ' of ' +
      (obj.count >= 1000 ? '>1000' : obj.count)
    );
  };

  return (
    <Paper className={classes.root}>
      <TablePagination
        labelRowsPerPage={t('LabelRowsPerPage')}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {(() => {
            if (!dataFetched) {
              return <MaterialSpinner />;
            } else if (totalCount === 0) {
              let row = {
                text: t('no_conversations')
              };
              return (
                <TableBody>
                  <MaterialTableRow key="-1" row={row} columns={columns} />
                </TableBody>
              );
            } else if (totalCount === -1) {
              let row = {
                text: t('fetching_data_error')
              };
              return (
                <TableBody>
                  <MaterialTableRow key="-1" row={row} columns={columns} />
                </TableBody>
              );
            } else {
              console.log('Total count: ' + totalCount);

              if (data) {
                console.log('Data length: ' + data.length);
                return (
                  <TableBody>
                    {data.map((row) => {
                      return (
                        <MaterialTableRow
                          key={row.id}
                          row={row}
                          columns={columns}
                          onClick={() => props.onClick(row)}
                        />
                      );
                    })}
                  </TableBody>
                );
              } else {
                let row = {
                  text: t('fetching_data_error')
                };
                return (
                  <TableBody>
                    <MaterialTableRow key="-1" row={row} columns={columns} />
                  </TableBody>
                );
              }
            }
          })()}
        </Table>
      </TableContainer>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <TablePagination
          labelRowsPerPage={t('LabelRowsPerPage')}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};

export default StickyHeadTable;
