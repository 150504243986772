import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { Add, Remove } from '@material-ui/icons';
import CoachingKangaroo from '../../../../api/CoachingKangaroo';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const renderContext = (contextItem) => {
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="flex-end"
      key={Math.random()}
    >
      <Grid item xs={8}>{`${contextItem.name} : ${contextItem.text}`}</Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

const ConversationItemContext = (props) => {
  const [openContext, setOpenContext] = useState(false);
  const [context, setContext] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const { t } = useTranslation();

  const getContext = () => {
    CoachingKangaroo.get(
      '/api/training/v1/' +
        encodeURIComponent(props.project) +
        '/context/' +
        encodeURIComponent(props.answerId)
    )
      .then((response) => {
        setContext(response.data);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error while putting data', error);
      });
  };

  return (
    <React.Fragment>
      {openContext ? (
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item xs={8}>
            <Button
              size="small"
              startIcon={<Remove />}
              onClick={() => setOpenContext(false)}
            >
              {t('Close context')}
            </Button>
            <div>
              {!dataFetched && <CircularProgress size={20} />}
              {dataFetched && context.length === 0 && (
                <span>{t('No context')}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      ) : (
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item xs={8}>
            <Button
              size="small"
              startIcon={<Add />}
              onClick={() => {
                setOpenContext(true);
                getContext();
              }}
            >
              {t('Display context')}
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
      {context.length > 0 ? (
        <Collapse in={openContext}>
          {context.map((contextItem) => {
            return renderContext(contextItem);
          })}
        </Collapse>
      ) : null}
    </React.Fragment>
  );
};
export default ConversationItemContext;
