import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 250,
    maxWidth: 250
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350
    }
  }
};

const GreenCheckbox = withStyles({
  root: {
    color: green[600]
  }
})((props) => <Checkbox color="default" {...props} />);

const ConversationFeelingSelect = (props) => {
  const classes = useStyles();
  const [selectedFeelings, setSelectedFeelings] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedFeelings(props.selectedFeelings);
  }, [props.selectedFeelings]);

  const handleChange = (event) => {
    setSelectedFeelings(event.target.value);
    props.setSelectedFeelings(event.target.value);
    console.log('Selected Feelings: ' + event.target.value);
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">
          {t('Conversation Feeling')}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedFeelings}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {props.feelings
            ? props.feelings.map((feeling) => (
                <MenuItem key={feeling} value={feeling}>
                  <GreenCheckbox
                    checked={selectedFeelings.indexOf(feeling) > -1}
                  />
                  <ListItemText primary={feeling} />
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default ConversationFeelingSelect;
