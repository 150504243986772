export const TIME_FROM = 'T00:00:00';
export const TIME_TO = 'T23:59:59';

export const formatDateToYyyyMmDd = (date) => {
  return new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
    .format(date)
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
};
