import React from 'react';
import { Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';

const AlertMassage = (props) => {
  const { severityType } = props;
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={props.open}
        autoHideDuration={severityType === 'success' ? 2000 : 6000}
        onClose={props.handleClose}
        action={[
          <IconButton key="close" onClick={props.handleClose}></IconButton>
        ]}
      >
        <Alert onClose={props.handleClose} severity={severityType}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AlertMassage;
