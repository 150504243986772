import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ConversationItemIntents from './ConversationItemIntents/ConversationItemIntents';
import ConversationItemResponse from './ConversationItemResponse/ConversationItemResponse';
import { AnswerRatingButtons } from './AnswerRatingButtons/AnswerRatingButtons';
import AnswerStatusButtons from './AnswerStatusButtons/AnswerStatusButtons';
import ConversationItemComments from './ConversationItemComments/ConversationItemComments';
import ConversationItemEntities from './ConversationItemEntities/ConversationItemEntities';
import ConversationItemContext from './ConversationItemContext/ConversationItemContext';

const useStyles = makeStyles((theme) => ({
  // container: {
  //     display: 'grid',
  //     gridTemplateColumns: 'repeat(12, 1fr)',
  //     gridGap: theme.spacing(3),
  // },
  paperLeft: {
    padding: theme.spacing(1),
    backgroundColor: '#d1d1d1',
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(1)
  }
}));

const getColorBySource = (source) => {
  if (source === 'ALZA-VOCALLS') return { backgroundColor: 'rgb(255 238 252)' };
  //TODO - dočasné řešení, při dalším importu připravíme administrovatelné nastavení barev a source kódů (jako další budeme řešit import pro O2)
};

const ConversationItem = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justify="space-around"
      style={getColorBySource(props.data['source'])}
    >
      <Grid item container xs={4}>
        <Grid item>
          <Grid item>
            <Paper className={classes.paperLeft}>
              {props.data['request-text']
                ? props.data['request-text']
                : '(Chat opened ...)'}
            </Paper>
          </Grid>
        </Grid>
        <Grid item container>
          {props.data && props.data.messages ? (
            <ConversationItemResponse
              messages={props.data.messages}
              inputType={props.data.inputType}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={4} container spacing={2}>
        <Grid item xs={12}>
          <ConversationItemIntents intents={props.data.intents} />
        </Grid>
        <Grid item xs={12}>
          <ConversationItemEntities entities={props.data.entities} />
        </Grid>
        <Grid item xs={12}>
          <ConversationItemContext
            project={props.project}
            answerId={props.data.id}
          />
        </Grid>
      </Grid>
      <Grid item container xs={4}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <AnswerRatingButtons
              project={props.project}
              conversationId={props.conversationId}
              answerId={props.data.id}
              rating={props.data.rating}
            />
          </Grid>
          <Grid item xs={6}>
            <AnswerStatusButtons
              project={props.project}
              conversationId={props.conversationId}
              answerId={props.data.id}
              status={props.data.status}
            />
          </Grid>
        </Grid>
        <ConversationItemComments
          project={props.project}
          conversationId={props.conversationId}
          answerId={props.data.id}
          comments={props.data.comments}
        />
      </Grid>
    </Grid>
  );
};

export default ConversationItem;
