import axios from 'axios';

export default axios.create({
  baseURL:
    (!window.g_viaCamelBaseUrl?.startsWith('%')
      ? window.g_viaCamelBaseUrl
      : null) ??
    process.env.REACT_APP_CAMEL_BASE_URL ??
    '/tc',
  headers: {
    'Content-Type': 'application/json'
  }
});
