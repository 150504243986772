import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  url:
    (!window.g_viaKeycloakUrl?.startsWith('%')
      ? window.g_viaKeycloakUrl
      : null) ??
    process.env.REACT_APP_KEYCLOAK_URL ??
    'https://id.addai.cz/auth',
  realm:
    (!window.g_viaKeycloakRealm?.startsWith('%')
      ? window.g_viaKeycloakRealm
      : null) ??
    process.env.REACT_APP_KEYCLOAK_REALM ??
    'addai',
  clientId:
    (!window.g_viaKeycloakClientId?.startsWith('%')
      ? window.g_viaKeycloakClientId
      : null) ??
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID ??
    'training-tool'
});

export default keycloak;
