import React, { useState } from 'react';
import AlertMassage from '../controls/SnackBar';

export default function useAlert() {
  const [status, setStatusBase] = useState();
  const [open, setOpen] = React.useState(true);

  const handleAlertClose = () => {
    setOpen(false);
    setStatusBase('');
  };

  const Alert = () => (
    <AlertMassage
      style={{
        position: 'relative'
      }}
      severityType={status.severityType}
      open={open}
      key={status.key}
      message={status.msg}
      handleClose={handleAlertClose}
    />
  );

  const showSuccess = () => {
    setOpen(true);
    setStatusBase({
      msg: 'Success',
      key: Math.random(),
      severityType: 'success'
    });
  };

  const showError = (error) => {
    let message = '';
    if (error.response) {
      if (error.response.status >= 500 && error.response.status <= 599) {
        message = 'Something went wrong please try again later';
      } else if (error.response.status === 401) {
        message = "We're sorry, but we're unable to verify you";
      } else {
        message = error.response.data
          ? error.response.data.message
          : error.message;
      }
    } else {
      message = error;
    }
    setStatusBase({ msg: message, key: Math.random(), severityType: 'error' });
    setOpen(true);
  };

  return {
    Alert,
    status,
    showSuccess,
    showError,
    handleAlertClose
  };
}
