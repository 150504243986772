import axiosClient from '../api/CoachingKangaroo';

const API_V1_PREFIX = '/api/training/v1';
const SUB_PATH_CONVERSATIONS = '/conversations';

export function getConversation(projectId, conversationID) {
  return axiosClient.get(
    `${API_V1_PREFIX}/${projectId}${SUB_PATH_CONVERSATIONS}/${conversationID}`
  );
}
