class User {
  constructor(
    username = '',
    firstname = '',
    lastname = '',
    email = '',
    roles = [],
    context = []
  ) {
    this.username = username;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.roles = roles;
    this.context = context;
  }
}

export default User;
