import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CoachingKangaroo from '../../../../api/CoachingKangaroo';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import useAlert from '../../../hooks/useAlert';
import { isTrainer } from '../../../../services/authService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  smallText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular
  },
  iconContainer: {
    fontSize: theme.typography.pxToRem(12)
  },
  iconButtonComment: {
    fontSize: 12,
    margin: '3px',
    padding: '3px'
  },
  formControl: {
    minWidth: '100%',
    maxWidth: '100%'
  }
}));

const ConversationItemComments = (props) => {
  const classes = useStyles();
  const [originalCommentText, setOriginalCommentText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [commentSaved, setCommentSaved] = useState(true);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { Alert, status, showError } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.comments && props.comments.length > 0) {
      setOriginalCommentText(props.comments[props.comments.length - 1].text);
      setCommentText(props.comments[props.comments.length - 1].text);
    }
  }, [props.comments]);

  const changeInputValue = (event) => {
    setCommentText(event.currentTarget.value);
    if (originalCommentText === event.currentTarget.value) {
      setCommentSaved(true);
    } else {
      setCommentSaved(false);
    }
  };

  const changeAnswer = (event) => {
    event.preventDefault();

    if (commentSaved) {
      return;
    }

    setSavingInProgress(true);

    let requestBody = { comment: commentText };

    // /{clientId}/conversation/{conversationId}/answer/{answerId}
    CoachingKangaroo.put(
      '/api/training/v1/' +
        encodeURIComponent(props.project) +
        '/conversation/' +
        props.conversationId +
        '/answer/' +
        props.answerId,
      JSON.stringify(requestBody)
    )
      .catch((error) => {
        showError(error);
      })
      .finally(() => {
        setOriginalCommentText(commentText);
        setCommentSaved(true);
        setSavingInProgress(false);
      });
  };

  return (
    <React.Fragment>
      {status ? <Alert /> : null}
      <Grid
        container
        justify="flex-end"
        alignItems="flex-end"
        style={{ marginTop: '1em' }}
      >
        <Grid item xs={11}>
          <form noValidate autoComplete="off" onSubmit={changeAnswer}>
            <FormControl
              onClick={() =>
                isTrainer(props.project)
                  ? undefined
                  : showError(
                      'You do not have enough permissions to edit conversations.'
                    )
              }
              className={classes.formControl}
            >
              <TextField
                label={
                  commentText
                    ? commentSaved
                      ? '  '
                      : t('Click Send or hit Enter to save')
                    : t('Add new Comment')
                }
                value={commentText}
                onChange={changeInputValue}
                disabled={savingInProgress}
                {...(!isTrainer(props.project) && { disabled: true })}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      {...(!isTrainer(props.project) && { disabled: true })}
                      type="submit"
                      aria-label="send"
                      className={classes.iconButtonComment}
                      color={commentSaved ? 'default' : 'primary'}
                    >
                      <SendIcon fontSize="small" />
                    </IconButton>
                  ),
                  style: { fontSize: 12 }
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ConversationItemComments;
