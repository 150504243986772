import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CoachingKangaroo from '../../../../api/CoachingKangaroo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import useAlert from '../../../hooks/useAlert';
import { isTrainer } from '../../../../services/authService';
import * as conversationService from '../../../../services/conversationService';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  fontAwesomeIcon: {
    fontSize: '2em',
    marginLeft: '10px',
    color: grey[600]
  }
}));

const ConversationFeelingButtons = (props) => {
  const classes = useStyles();
  const [feeling, setFeeling] = useState('NOT_RATED');
  const { Alert, status, showError } = useAlert();
  const [dataFetched, setDataFetched] = useState(true);
  const changeFeeling = (feelingCode) => {
    setFeeling(feelingCode);
    let requestBody = { feeling: feelingCode };
    setDataFetched(false);
    // /{clientId}/conversation/{conversationId}
    CoachingKangaroo.put(
      '/api/training/v1/' +
        encodeURIComponent(props.project) +
        '/conversation/' +
        props.conversationId,
      JSON.stringify(requestBody)
    )
      .then(() => {
        conversationService
          .getConversation(props.project, props.conversationId)
          .then((response) => {
            const result = response.data;
            if (result) {
              props.setDirtyConversation(result);
            }
          })
          .catch((error) => {
            showError(error);
          });
      })
      .catch((error) => {
        showError(error);
      })
      .finally(() => {
        setDataFetched(true);
      });
  };

  useEffect(() => {
    setFeeling(props.feeling);
  }, [props.feeling]);

  return (
    <React.Fragment>
      {status ? <Alert /> : null}
      <Grid container>
        {!dataFetched ? (
          <CircularProgress size={20} />
        ) : (
          <React.Fragment>
            <Grid item>
              <FontAwesomeIcon
                className={classes.fontAwesomeIcon}
                style={feeling === 'BAD' ? { color: red[700] } : {}}
                icon={faFrown}
                onClick={() =>
                  isTrainer(props.project)
                    ? changeFeeling('BAD')
                    : showError(
                        'You do not have enough permissions to edit conversations.'
                      )
                }
              />
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                className={classes.fontAwesomeIcon}
                {...(!isTrainer(props.project) && { disabled: true })}
                style={feeling === 'NONE' ? { color: yellow[700] } : {}}
                icon={faMeh}
                onClick={() =>
                  isTrainer(props.project)
                    ? changeFeeling('NONE')
                    : showError(
                        'You do not have enough permissions to edit conversations.'
                      )
                }
              />
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                className={classes.fontAwesomeIcon}
                {...(!isTrainer(props.project) && { disabled: true })}
                style={feeling === 'GOOD' ? { color: green[700] } : {}}
                icon={faSmile}
                onClick={() =>
                  isTrainer(props.project)
                    ? changeFeeling('GOOD')
                    : showError(
                        'You do not have enough permissions to edit conversations.'
                      )
                }
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {props.feelingAI && (
        <div>
          <IconButton disabled aria-label="openAI">
            <img
              alt={'avatar'}
              style={{ width: '2.5rem', height: '2.5rem' }}
              src={'./ai_avatar.png'}
            />
            <Typography style={{ color: 'black' }}>:</Typography>
            <FontAwesomeIcon
              {...(!isTrainer(props.project) && { disabled: true })}
              style={{
                fontSize: '2em',
                marginLeft: '5px',
                color:
                  props.feelingAI === 'NONE'
                    ? yellow[700]
                    : props.feelingAI === 'GOOD'
                      ? green[700]
                      : red[700]
              }}
              icon={
                props.feelingAI === 'NONE'
                  ? faMeh
                  : props.feelingAI === 'GOOD'
                    ? faSmile
                    : faFrown
              }
            />
          </IconButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default ConversationFeelingButtons;
